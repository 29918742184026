import { combineReducers } from 'redux';
import { notificationsReducer } from './notifications/ducks';
import { usersReducer } from './users/ducks';

const rootReducer = combineReducers({
  notifications: notificationsReducer,
  users: usersReducer,
});

export default rootReducer;
