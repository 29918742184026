import firebase from 'firebase/app';
import { getAuthErrorMessage } from '../utils/auth_utils';

const createUserRecord = async (user) => {
  try {
    await import('firebase/firestore').then(() =>
      firebase.firestore().collection('users').doc(user.uid).set({
        name: user.displayName,
        email: user.email,
        id: user.uid,
        reports: 0,
        type: 'patient',
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
    );
  } catch (e) {
    console.error(e);
    return e.message;
  }
};

export const createUser = async (name, email) => {
  try {
    const { user } = await firebase.auth().createUserWithEmailAndPassword(email, Math.random().toString(36).slice(2));
    await user.updateProfile({ displayName: name });
    return createUserRecord(user);
  } catch (e) {
    console.error(e);
    return getAuthErrorMessage(e.code);
  }
};

export const checkPhoneNumberExist = async (phoneNumber) =>
  firebase
    .app()
    .functions('asia-south1')
    .httpsCallable('checkPhoneNumberExist')({ phoneNumber })
    .then((res) => res.data.exists);

export const updatePhoneNumber = async (mobile) => {
  await import('firebase/auth');
  await import('firebase/firestore').then(() =>
    firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .update({ phoneNumber: `+91${mobile}`, updatedAt: firebase.firestore.FieldValue.serverTimestamp() })
  );
};

export const linkWithGoogle = async () =>
  firebase.auth().currentUser.linkWithPopup(new firebase.auth.GoogleAuthProvider());

export const signupWithGoogle = async () => {
  try {
    const { user } = await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
    return createUserRecord(user);
  } catch (e) {
    console.error(e);
    return getAuthErrorMessage(e.code);
  }
};

export const signOut = () => firebase.auth().signOut();
