import React from 'react';
import Layout from '../components/navigation/Layout';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 56px)',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 128px)',
    },
    justifyContent: 'center',
    width: '100%',
  },
  pad: {
    marginBottom: 56,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 128,
    },
  },
}));

const LoadingScreen = ({ disableLayout }) => {
  const classes = useStyles();

  const children = (
    <div className={classes.wrapper}>
      <div className='loader'>Loading...</div>
      <Typography className={classes.pad} color={'textPrimary'} variant={'body1'}>
        Loading . . .
      </Typography>
    </div>
  );

  if (disableLayout) {
    return children;
  }

  return <Layout loading={true}>{children}</Layout>;
};

LoadingScreen.propTypes = {
  disableLayout: PropTypes.bool,
};

LoadingScreen.defaultProps = {
  disableLayout: false,
};

export default LoadingScreen;
