import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ThemeProvider } from '@material-ui/core/styles';
import { createGlobalTheme } from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';

class ThemeWrapper extends Component {
  state = { language: this.props.i18n.language };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.state.language !== this.props.i18n.language && this.state.language === nextState.language) {
      this.setState({ language: this.props.i18n.language });
      return true;
    }
    return false;
  }

  render() {
    return (
      <ThemeProvider theme={createGlobalTheme(this.props.i18n.language)}>
        <CssBaseline />
        {this.props.children}
      </ThemeProvider>
    );
  }
}

export default withTranslation()(ThemeWrapper);
