import Logo300Png from '../../assets/images/logo/png/logo-300w.png';
import Logo300Webp from '../../assets/images/logo/webp/logo-300w.webp';

const LogoImageSources = {
  metadata: { alt: 'Medical Point Logo' },
  sources: [
    {
      minWidth: 0,
      size: 300,
      png: Logo300Png,
      webp: Logo300Webp,
    },
  ],
};

export default LogoImageSources;
