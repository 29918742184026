import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import Notification from '../common/Notification';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, loading }) => {
  return (
    <>
      <Suspense fallback={<></>}>
        <Header loading={loading} />
      </Suspense>
      <main>
        {children}
        <Notification />
      </main>
      <Suspense fallback={<></>}>
        <Footer loading={loading} />
      </Suspense>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};
Layout.defaultProps = {
  loading: false,
};

export default Layout;
