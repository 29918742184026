import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, MenuItem, Paper, Select, Toolbar, Typography, useMediaQuery } from '@material-ui/core';
import { Email as EmailIcon, Phone as PhoneIcon } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { signOutUser, userSelector } from '../../redux/users/ducks';
import { useHistory } from 'react-router';
import { getEnglishUrl, getUrl } from '../../utils/translation_utils';
import ResponsiveImage from '../common/ResponsiveImage';
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';
import LogoImageSources from '../../utils/images/LogoImageSources';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: theme.spacing(20),
  },
  line: {
    border: `1px solid ${theme.palette.secondary.main}`,
    height: theme.spacing(4),
    margin: theme.spacing(0, 2),
  },
}));

const Header = ({ loading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const user = useSelector(userSelector);
  const { t, i18n } = useTranslation(['navigation', 'auth']);

  const onLanguageChange = (lang) => {
    const url = lang === 'en' ? getEnglishUrl() : `/${lang}${window.location.pathname}`;
    if (history) history.replace(url);
    i18n.changeLanguage('en');
  };

  return (
    <header>
      <Paper>
        <Toolbar style={{ alignItems: 'center', justifyContent: isMobile ? 'space-between' : 'flex-start' }}>
          {isMobile ? (
            <MobileHeader loading={loading} />
          ) : (
            <>
              <ResponsiveImage className={classes.logo} image={LogoImageSources} />
              <PhoneIcon color={'secondary'} style={{ marginLeft: `auto`, marginRight: '8px' }} />
              <Typography noWrap={true} style={{ fontSize: '14px' }} variant={'h6'}>
                {t('top.phone')}
              </Typography>
              <span className={classes.line} />
              <EmailIcon color={'secondary'} style={{ marginRight: '8px' }} />
              <Typography noWrap={true} style={{ fontSize: '14px' }} variant={'h6'}>
                {t('top.email')}
              </Typography>
            </>
          )}
          <Select
            onChange={(event) => onLanguageChange(event.target.value)}
            style={{ margin: isMobile ? 0 : '0 2rem' }}
            value={i18n.language}
          >
            <MenuItem value={'en'}>English</MenuItem>
            <MenuItem value={'hi'}>हिंदी</MenuItem>
          </Select>
          {isMobile ? (
            <></>
          ) : user ? (
            <Button color={'primary'} onClick={() => dispatch(signOutUser())} variant={'outlined'}>
              {t('auth:logout')}
            </Button>
          ) : (
            <Button color={'secondary'} href={getUrl(i18n.language, '/signup')} variant={'contained'}>
              {t('auth:signup')}
            </Button>
          )}
        </Toolbar>
      </Paper>
      {isMobile ? <></> : <DesktopHeader loading={loading} />}
    </header>
  );
};

Header.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Header;
