import { fork } from '@redux-saga/core/effects';
import { usersSaga } from './users/sagas';
import { notificationsSaga } from './notifications/sagas';

function* rootSaga() {
  yield fork(notificationsSaga);
  yield fork(usersSaga);
}

export default rootSaga;
