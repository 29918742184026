import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Container, Link as MuiLink, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getNamesObject, getUrl } from '../../utils/translation_utils';
import { AccessTime, ChevronRight } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Header from './Header';
import Title from '../common/Title';
import GoogleMapIcon from '../../assets/svgs/google_maps_icon.svg';

const useStyles = makeStyles((theme) => ({
  footer: {
    borderTop: `4px solid ${theme.palette.primary.main}`,
    paddingTop: theme.spacing(3),
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(2),
    [theme.breakpoints.up(350)]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  center: {
    margin: theme.spacing(0, 'auto'),
  },
  departments: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up(884)]: {
      gridTemplateColumns: 'repeat(2, 175px)',
    },
  },
  healthPlans: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up(884)]: {
      gridTemplateColumns: 'repeat(2, 235px)',
    },
  },
  titleLink: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  link: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  bottom: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2, 0),
    '& p, a': {
      color: theme.palette.common.white,
      display: 'block',
      fontWeight: 300,
      textAlign: 'center',
    },
    '& svg': {
      fontSize: theme.spacing(2.25),
    },
  },
  flex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      marginRight: theme.spacing(0.5),
    },
  },
}));

const Footer = ({ loading }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(['common']);

  const customLink = (name, url, isTitle) => {
    return loading ? (
      <MuiLink className={isTitle ? classes.titleLink : classes.link} href={getUrl(i18n.language, url)}>
        {name}
      </MuiLink>
    ) : (
      <Link className={isTitle ? classes.titleLink : classes.link} to={getUrl(i18n.language, url)}>
        {name}
      </Link>
    );
  };

  return (
    <footer className={classes.footer}>
      <Container maxWidth={'lg'}>
        <div className={classes.wrapper}>
          <div className={classes.center}>
            {customLink(
              <Title colored={t('departments')} hover underlined={t('our')} isPrimary={true} variant={'body2'} />,
              '/departments',
              true
            )}
            <List aria-label={t('departments')} className={classes.departments} style={{ paddingTop: 0 }}>
              {getNamesObject('en', i18n.getResource(i18n.language, 'departments'), '/departments/').map(
                (department, index) => (
                  <ListItem style={{ padding: 0 }} key={index}>
                    <ListItemIcon style={{ minWidth: '1.5rem' }}>
                      <ChevronRight color={'primary'} fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText primary={customLink(department.name, department.url)} />
                  </ListItem>
                )
              )}
            </List>
          </div>
          <div className={classes.center}>
            {customLink(
              <Title
                colored={t('health plans').split(' ')[1]}
                hover
                underlined={t('health plans').split(' ')[0]}
                isPrimary={true}
                variant={'body2'}
              />,
              '/health-plans',
              true
            )}
            <List aria-label={t('health plans')} className={classes.healthPlans} style={{ paddingTop: 0 }}>
              {getNamesObject('en', i18n.getResource(i18n.language, 'packages'), '/health-plans/').map(
                (healthPlan, index) => (
                  <ListItem style={{ padding: 0 }} key={index}>
                    <ListItemIcon style={{ minWidth: '1.5rem' }}>
                      <ChevronRight color={'primary'} fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText primary={customLink(healthPlan.name, healthPlan.url)} />
                  </ListItem>
                )
              )}
            </List>
          </div>
        </div>
      </Container>
      <div className={classes.bottom}>
        <MuiLink
          href={`https://www.google.com/maps/place/Medical+Point/@27.613999,75.1467919,16z/data=!4m12!1m6!3m5!1s0x0:0x17925174683811!2sMedical+Point!8m2!3d27.6127473!4d75.1494697!3m4!1s0x0:0x17925174683811!8m2!3d27.6127473!4d75.1494697`}
          rel={'noopener'}
          style={{ marginBottom: '0.5rem' }}
          target={'_blank'}
        >
          <img alt={'Google Maps Location'} src={GoogleMapIcon} style={{ marginRight: '6px' }} width={20} />
          Behind S K Hospital, Sikar - 332001, Rajasthan
        </MuiLink>
        <div className={classes.flex}>
          <AccessTime style={{ fill: 'white' }} />
          <Typography variant={'body2'}>Timings: 9:00 AM - 9:00 PM</Typography>
        </div>
      </div>
      <Typography align={'center'} style={{ fontSize: '14px', margin: '8px 0' }} variant={'body2'}>
        Copyright © {new Date().getFullYear()} Medical Point, All Rights Reserved.
      </Typography>
    </footer>
  );
};

Header.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Footer;
