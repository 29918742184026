import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const ResponsiveImage = ({ className, image, minHeight }) => {
  const sources = image.sources.sort((a, b) => (a.minWidth > b.minWidth ? -1 : 1)); // Descending order
  const type = Boolean(sources[0].jpg) ? 'jpg' : Boolean(sources[0].jpeg) ? 'jpeg' : 'png';
  const styles = { minWidth: '100%' };
  if (minHeight) styles.minHeight = minHeight;
  if (type !== 'png') styles.backgroundColor = 'whitesmoke';

  return (
    <picture style={{ marginTop: '6.4px' }}>
      {sources.map((source, index) => (
        <Fragment key={index}>
          <source media={`(min-width: ${source.minWidth}px)`} srcSet={source.webp} type={`image/webp`} />
          <source media={`(min-width: ${source.minWidth}px)`} srcSet={source[type]} type={`image/${type}`} />
        </Fragment>
      ))}
      <img
        alt={image.metadata.alt}
        className={className}
        loading={'lazy'}
        sizes={sources.map((source) => `(min-width: ${source.minWidth}px) ${source.size}px`).join(', ')}
        srcSet={sources.map((source) => `${source[type]} ${source.size}w`).join(', ')}
        style={styles}
        src={sources[0][type]}
        width={'100%'}
      />
    </picture>
  );
};

ResponsiveImage.propTypes = {
  className: PropTypes.string,
  image: PropTypes.shape({
    metadata: PropTypes.shape({
      alt: PropTypes.string.isRequired,
    }).isRequired,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        minWidth: PropTypes.number.isRequired,
        size: PropTypes.number.isRequired,
        webp: PropTypes.string.isRequired,
        jpg: PropTypes.string,
        jpeg: PropTypes.string,
        png: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default ResponsiveImage;
