import firebase from 'firebase/app';
import moment from 'moment';

export const fetchUserProfile = (id, emit) =>
  firebase
    .firestore()
    .collection('users')
    .doc(id)
    .onSnapshot((doc) => {
      const data = doc.data();
      if (data) {
        data.createdAt = data.createdAt ? moment(data.createdAt.toDate()) : moment();
        data.updatedAt = data.updatedAt ? moment(data.updatedAt.toDate()) : moment();
        data.createdAt = data.updatedAt.format('DD MMM YYYY, hh:mm A');
        data.updatedAt = data.updatedAt.format('DD MMM YYYY, hh:mm A');
      }
      emit({ profile: data });
    });

// Returns 1 more item than requested (Not Required Here)
export const fetchReports = async (patientId, itemsPerPage, page) =>
  firebase
    .app()
    .functions('asia-south1')
    .httpsCallable('fetchReports')({ itemsPerPage, page, uid: patientId })
    .then((res) =>
      res.data.map((report) => {
        report.createdAt = moment.unix(report.createdAt).format('dd, Do MMM YY, hh:mm A');
        return report;
      })
    );

export const downloadReport = async (ref) =>
  import('firebase/storage').then(() => firebase.storage().ref(ref).getDownloadURL());
