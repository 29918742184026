import { put, takeLatest } from 'redux-saga/effects';
import { NotificationActionTypes } from './ducks';

export function* notificationsSaga() {
  yield takeLatest(NotificationActionTypes.NOTIFICATION_TIMEOUT, notificationTimeoutSaga);
}

function* notificationTimeoutSaga(action) {
  yield put({ type: NotificationActionTypes.NOTIFICATION_TIMEOUT_SUCCEEDED });
}
