import React from 'react';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  primaryHover: {
    '&:hover': {
      '& span:first-child': {
        borderBottomColor: `${theme.palette.primary.main} !important`,
      },
      '& span:last-child': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
  },
  secondaryHover: {
    '&:hover': {
      '& span:first-child': {
        borderBottomColor: `${theme.palette.secondary.main} !important`,
      },
      '& span:last-child': {
        color: `${theme.palette.secondary.main} !important`,
      },
    },
  },
}));

const Title = ({ colored, hover, isPrimary, underlined, variant }) => {
  const classes = useStyles();
  const theme = useTheme();
  const color = isPrimary ? theme.palette.primary.main : theme.palette.secondary.main;

  return (
    <div
      className={hover ? (isPrimary ? classes.secondaryHover : classes.primaryHover) : ''}
      style={{ display: `inline-block` }}
    >
      <Typography
        color={'textPrimary'}
        noWrap={true}
        variant={variant}
        style={{ paddingBottom: `12px`, display: `inline-block` }}
      >
        <span style={{ borderBottom: `4px solid ${color}`, paddingBottom: `4px` }}>{underlined} </span>
        <span style={{ color: color }}>{colored}</span>
      </Typography>
    </div>
  );
};

Title.propTypes = {
  colored: PropTypes.string.isRequired,
  hover: PropTypes.bool,
  isPrimary: PropTypes.bool,
  underlined: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

Title.defaultProps = {
  hover: false,
  isPrimary: false,
  variant: `h1`,
};

export default Title;
