export const getNamesObject = (language, object, prefix) => {
  const languagePrefix = language === 'en' ? '' : '/' + language;
  return object
    ? Object.entries(object).map(([key, value]) => ({ url: languagePrefix + prefix + key, name: value.name }))
    : [];
};

export const getEnglishUrl = () => {
  const path = window.location.pathname.substring(3);
  return path ? path : '/';
};

export const getUrl = (language, path) => {
  if (language === 'en') return path;
  return `/${language}${path}`;
};
