import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { profileSelector, setUser, UserActionTypes, userSelector } from './ducks';
import { linkWithGoogle, signOut, signupWithGoogle } from '../../services/auth_service';
import { getAuthErrorMessage } from '../../utils/auth_utils';
import { NotificationActionTypes } from '../notifications/ducks';
import { fetchUserProfile } from '../../services/user_service';
import { eventChannel } from '@redux-saga/core';

export function* usersSaga() {
  yield takeLatest(UserActionTypes.SIGN_OUT_USER, signOutUser);
  yield takeLatest(UserActionTypes.SIGNUP_WITH_GOOGLE, signupWithGoogleSaga);
  yield takeLatest(UserActionTypes.LINK_WITH_GOOGLE, linkWithGoogleSaga);

  yield takeLatest(UserActionTypes.SET_CLAIMS, setClaimsSaga);
  yield takeLatest(UserActionTypes.SUBSCRIBE_USER_PROFILE, fetchProfileSaga);
}

function* signOutUser(action) {
  try {
    yield call(signOut);
    yield put({ type: 'CLEAR_STATE' });
  } catch (e) {
    console.error(e.message);
    yield put({
      type: NotificationActionTypes.SET_NOTIFICATION,
      payload: { message: getAuthErrorMessage(e.code), type: 'error', show: true },
    });
  }
}

function* linkWithGoogleSaga(action) {
  try {
    const userData = yield select(userSelector);
    const { user } = yield call(linkWithGoogle, userData);
    yield put(setUser(user));
  } catch (e) {
    console.error(e.message);
    yield put({
      type: NotificationActionTypes.SET_NOTIFICATION,
      payload: { message: getAuthErrorMessage(e.code), type: 'error', show: true },
    });
  }
}

function* signupWithGoogleSaga(action) {
  try {
    yield call(signupWithGoogle);
  } catch (e) {
    console.error(e.message);
    yield put({
      type: NotificationActionTypes.SET_NOTIFICATION,
      payload: { message: getAuthErrorMessage(e.code), type: 'error', show: true },
    });
  }
}

const fetchProfileListener = (id) => eventChannel((emit) => fetchUserProfile(id, emit));

function* fetchProfileSaga(action) {
  try {
    yield import('firebase/firestore');
    const listener = fetchProfileListener(action.payload.id);
    while (true) {
      const { profile } = yield take(listener);
      yield put({ type: UserActionTypes.SET_USER_PROFILE, payload: { profile } });
    }
  } catch (e) {
    console.error(e.message);
  }
}

function* setClaimsSaga() {
  const user = yield select(userSelector);
  const profile = yield select(profileSelector);
  if (user && (!profile || profile.id !== user.uid)) {
    yield import('firebase/firestore');
    yield put({ type: UserActionTypes.SUBSCRIBE_USER_PROFILE, payload: { id: user.uid } });
  }
}
