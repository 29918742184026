const INITIAL_STATE = {
  user: undefined,
  claims: { loading: true },
  profile: undefined,
};

export const UserActionTypes = {
  SIGNUP_WITH_GOOGLE: 'SIGNUP_WITH_GOOGLE',
  LINK_WITH_GOOGLE: 'LINK_WITH_GOOGLE',
  SIGN_OUT_USER: 'SIGN_OUT_USER',

  SUBSCRIBE_USER_PROFILE: 'SUBSCRIBE_USER_PROFILE',

  SET_USER: 'SET_USER',
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  SET_CLAIMS: 'SET_CLAIMS',

  CLEAR_STATE: 'CLEAR_STATE',
};

export const signOutUser = () => ({ type: UserActionTypes.SIGN_OUT_USER });
export const signupWithGoogle = () => ({ type: UserActionTypes.SIGNUP_WITH_GOOGLE });
export const linkWithGoogle = () => ({ type: UserActionTypes.LINK_WITH_GOOGLE });

export const setUser = (user) => ({ type: UserActionTypes.SET_USER, payload: { user } });
export const setClaims = (claims) => ({ type: UserActionTypes.SET_CLAIMS, payload: { claims } });

export const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_USER:
      return { ...state, user: action.payload.user };

    case UserActionTypes.SET_USER_PROFILE:
      return { ...state, profile: action.payload.profile };

    case UserActionTypes.SET_CLAIMS:
      return { ...state, claims: action.payload.claims };

    case UserActionTypes.CLEAR_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const userSelector = (state) => state.users.user;
export const profileSelector = (state) => state.users.profile;
export const isAuthenticating = (state) => Boolean(state.users.claims.loading);
