import { createTheme, responsiveFontSizes } from '@material-ui/core';

export const createGlobalTheme = (lang) => {
  const primary = `#0093DE`;
  const secondary = `#D7261A`;
  const textPrimary = `#212121`;
  const textSecondary = `#616161`;
  const white = `#FFF`;

  const lineHeight = lang === 'hi' ? 2 : 1.5;
  const fontFamily = lang === 'hi' ? `'Hind', sans-serif` : `'Poppins', 'Roboto', 'Oxygen', 'Ubuntu', sans-serif`;
  const wordSpacing = lang === 'hi' ? `2` : `normal`;

  return responsiveFontSizes(
    createTheme({
      overrides: {
        MuiSelect: {
          root: {
            fontSize: lang === 'hi' ? '16px !important' : '14px !important',
          },
          select: {
            '&:focus': {
              backgroundColor: white,
            },
          },
        },
        MuiMenuItem: {
          root: {
            fontSize: lang === 'hi' ? '16px !important' : '14px !important',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        MuiFormHelperText: {
          root: {
            fontSize: lang === 'hi' ? 16 : 14,
          },
        },
        MuiInputBase: {
          root: {
            fontSize: lang === 'hi' ? '18px !important' : '16px !important',
          },
        },
        MuiInputLabel: {
          root: {
            fontSize: '16px',
          },
        },
        MuiCssBaseline: {
          '@global': {
            body: {
              fontFamily: fontFamily,
              wordSpacing: wordSpacing,
            },
            a: {
              cursor: 'pointer',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
                textUnderlinePosition: 'under',
              },
            },
          },
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      palette: {
        primary: {
          main: primary,
          contrastText: white,
        },
        secondary: {
          main: secondary,
          contrastText: white,
        },
        text: {
          primary: textPrimary,
          secondary: textSecondary,
        },
      },
      typography: {
        fontFamily: fontFamily,
        h1: {
          color: textPrimary,

          fontSize: lang === 'hi' ? 36 : 32,
          fontWeight: `700`,
        },
        h2: {
          color: textPrimary,
          fontSize: lang === 'hi' ? 28 : 24,
          fontWeight: `700`,
        },
        h3: {
          color: textPrimary,
          fontSize: lang === 'hi' ? 22 : 20,
          fontWeight: `700`,
        },
        // TODO CHANGE TO H4
        h6: {
          color: textPrimary,
          fontSize: lang === 'hi' ? 18 : 16,
          fontWeight: `500`,
        },
        subtitle1: {
          color: textPrimary,
          fontSize: lang === 'hi' ? 22 : 20,
          fontWeight: `600`,
        },
        subtitle2: {
          color: textPrimary,
          fontSize: lang === 'hi' ? 18 : 16,
          fontWeight: `400`,
        },
        body1: {
          color: textSecondary,
          fontSize: lang === 'hi' ? 22 : 20,
          fontWeight: `400`,
          lineHeight: lineHeight,
        },
        body2: {
          color: textSecondary,
          fontSize: lang === 'hi' ? 18 : 16,
          fontWeight: `400`,
          lineHeight: lineHeight,
        },
        button: {
          fontSize: lang === 'hi' ? 18 : 16,
        },
      },
    })
  );
};
