import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Drawer,
  IconButton,
  Link as MuiLink,
  MenuItem,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, Menu as MenuIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { getNamesObject, getUrl } from '../../utils/translation_utils';
import PropTypes from 'prop-types';
import ResponsiveImage from '../common/ResponsiveImage';
import { useDispatch, useSelector } from 'react-redux';
import { signOutUser, userSelector } from '../../redux/users/ducks';
import LogoImageSources from '../../utils/images/LogoImageSources';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(15),
    },
  },
  menuItem: {
    height: theme.spacing(6),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
}));

const MobileHeader = ({ loading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation(['navigation', 'departments', 'packages', 'auth']);
  const user = useSelector(userSelector);

  const toggleDrawer = (status) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
    setOpen(status);
  };

  return (
    <>
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon style={{ fontSize: '1rem' }} />
      </IconButton>
      <ResponsiveImage className={classes.logo} image={LogoImageSources} />
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '15rem' }}>
          <div style={{ padding: '8px 1rem' }}>
            <ResponsiveImage className={classes.logo} image={LogoImageSources} />
          </div>

          <Divider />
          <MenuItem className={classes.menuItem} component={loading ? MuiLink : Link} to={getUrl(i18n.language, '/')}>
            {t('bottom.home')}
          </MenuItem>

          <Divider />
          <MobileAccordion
            heading={t('bottom.departments')}
            items={getNamesObject(i18n.language, i18n.getResource(i18n.language, 'departments'), '/departments/')}
            loading={loading}
          />

          <Divider />
          <MobileAccordion
            heading={t('bottom.health_plans')}
            items={getNamesObject(i18n.language, i18n.getResource(i18n.language, 'packages'), '/health-plans/')}
            loading={loading}
          />

          <Divider />
          <MenuItem
            className={classes.menuItem}
            component={loading ? MuiLink : Link}
            to={getUrl(i18n.language, `/lab-tests`)}
          >
            {t('bottom.lab_tests')}
          </MenuItem>

          <Divider />
          <MenuItem
            className={classes.menuItem}
            component={loading ? MuiLink : Link}
            to={getUrl(i18n.language, `/contact`)}
          >
            {t('bottom.contact')}
          </MenuItem>
          <Divider />

          {user && (
            <>
              <MenuItem
                className={clsx(classes.menuItem, classes.secondary)}
                component={loading ? MuiLink : Link}
                to={getUrl(i18n.language, '/dashboard')}
              >
                {t('bottom.reports')}
              </MenuItem>
              <Divider />
            </>
          )}

          {user ? (
            <MenuItem
              className={classes.menuItem}
              style={{ marginTop: 'auto' }}
              onClick={() => dispatch(signOutUser())}
            >
              {t('auth:logout')}
            </MenuItem>
          ) : (
            <MenuItem
              className={clsx(classes.menuItem, classes.secondary)}
              component={loading ? MuiLink : Link}
              style={{ marginTop: 'auto' }}
              to={getUrl(i18n.language, '/signup')}
            >
              {t('auth:signup')}
            </MenuItem>
          )}
        </div>
      </Drawer>
    </>
  );
};

MobileHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default MobileHeader;

const useAccordionStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    margin: '0 !important',
    '&::before': {
      opacity: 0,
    },
  },
  content: {
    margin: '0 !important',
  },
  menuItem: {
    fontSize: '14px',
    paddingLeft: 0,
  },
  accordionItem: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  link: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const MobileAccordion = ({ heading, items, loading }) => {
  const classes = useAccordionStyles();

  return (
    <Accordion classes={{ root: classes.root }}>
      <AccordionSummary classes={{ content: classes.content }} expandIcon={<ExpandMoreIcon />}>
        <MenuItem className={classes.menuItem}>{heading}</MenuItem>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionItem}>
        <span>
          {items.map((item, index) => (
            <MenuItem
              className={classes.link}
              component={loading ? MuiLink : Link}
              key={index}
              href={item.url}
              to={item.url}
            >
              {item.name}
            </MenuItem>
          ))}
        </span>
      </AccordionDetails>
    </Accordion>
  );
};

MobileAccordion.propTypes = {
  heading: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, url: PropTypes.string.isRequired }))
    .isRequired,
  loading: PropTypes.bool.isRequired,
};
