import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Grow, Link as MuiLink, MenuItem, Paper, Popper, Toolbar } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { getNamesObject, getUrl } from '../../utils/translation_utils';
import PropTypes from 'prop-types';
import { ExpandMore } from '@material-ui/icons';
import { userSelector } from '../../redux/users/ducks';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  bottomNav: {
    backgroundColor: theme.palette.primary.main,
  },
  white: {
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.grey[200],
    },
  },
}));

const DesktopHeader = ({ loading }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(['navigation', 'departments', 'packages']);

  const user = useSelector(userSelector);

  return (
    <Paper className={classes.bottomNav} elevation={4}>
      <Toolbar className={classes.toolbar}>
        <MenuItem className={classes.white} component={loading ? MuiLink : Link} to={getUrl(i18n.language, '/')}>
          {t('bottom.home')}
        </MenuItem>

        <MenuList
          heading={t('bottom.departments')}
          items={getNamesObject(i18n.language, i18n.getResource(i18n.language, 'departments'), '/departments/')}
          loading={loading}
        />

        <MenuList
          heading={t('bottom.health_plans')}
          items={getNamesObject(i18n.language, i18n.getResource(i18n.language, 'packages'), '/health-plans/')}
          loading={loading}
        />

        <MenuItem
          className={classes.white}
          component={loading ? MuiLink : Link}
          to={getUrl(i18n.language, '/lab-tests')}
        >
          {t('bottom.lab_tests')}
        </MenuItem>

        <MenuItem className={classes.white} component={loading ? MuiLink : Link} to={getUrl(i18n.language, '/contact')}>
          {t('bottom.contact')}
        </MenuItem>

        {user && (
          <MenuItem
            className={classes.white}
            style={{ fontWeight: 500, textDecoration: 'underline', textUnderlinePosition: 'under' }}
            component={loading ? MuiLink : Link}
            to={getUrl(i18n.language, '/dashboard')}
          >
            {t('bottom.reports')}
          </MenuItem>
        )}
      </Toolbar>
    </Paper>
  );
};

DesktopHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default DesktopHeader;

const useMenuStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.common.white,
    '& svg': {
      fill: theme.palette.common.white,
      transition: 'transform 250ms ease',
    },
  },
  rotate: {
    transform: `rotate(180deg)`,
  },
  link: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const MenuList = ({ heading, items, loading }) => {
  const anchorRef = React.useRef(null);
  const classes = useMenuStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <MenuItem aria-haspopup={'true'} className={classes.heading} ref={anchorRef}>
        {heading}
        <ExpandMore className={clsx({ [classes.rotate]: open })} />
      </MenuItem>
      <Popper anchorEl={anchorRef.current} keepMounted open={open} role={'menu'} style={{ height: 0 }} transition>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper>
              {items.map((item, index) => (
                <MenuItem
                  className={classes.link}
                  component={loading ? MuiLink : Link}
                  key={index}
                  href={item.url}
                  to={item.url}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

MenuList.propTypes = {
  heading: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};
