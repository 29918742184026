import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Suspense, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setClaims, setUser } from './redux/users/ducks';
import firebase from 'firebase/app';
import withLanguageRedirect from './components/navigation/hoc/withLanguageRedirect';
import LoadingScreen from './pages/loadingScreen';
import ThemeWrapper from './styles/ThemeWrapper';

const LandingScreen = React.lazy(() => import('./pages/landingScreen'));
const ContactScreen = React.lazy(() => import('./pages/contactScreen'));
const DepartmentsScreen = React.lazy(() => import('./pages/departmentsScreen'));
const DepartmentScreen = React.lazy(() => import('./pages/departmentScreen'));
const HealthPlansScreen = React.lazy(() => import('./pages/healthPlansScreen'));
const HealthPlanScreen = React.lazy(() => import('./pages/healthPlanScreen'));
const LabTestsScreen = React.lazy(() => import('./pages/labTestsScreen'));
const NotFoundScreen = React.lazy(() => import('./pages/notFoundScreen'));

const LoginScreen = React.lazy(() => import('./pages/loginScreen'));
const SignupScreen = React.lazy(() => import('./pages/signupScreen'));
const VerifyMobileScreen = React.lazy(() => import('./pages/verifyMobileScreen'));
const DashboardScreen = React.lazy(() => import('./pages/dashboard/dashboardScreen'));
const ActionsScreen = React.lazy(() => import('./pages/actionsScreen'));

const App = () => {
  let authUnsubscribe = useRef(() => {});
  let authInitialized = useRef(false);
  const dispatch = useDispatch();
  const routeLngs = ['hi', 'en']; // Last language should be english

  useEffect(() => {
    import('firebase/auth').then(
      () =>
        (authUnsubscribe.current = firebase.auth().onAuthStateChanged((user) => {
          authInitialized.current = true;
          dispatch(setUser(user));
          if (user !== null) {
            user.getIdTokenResult().then((tokenResult) => dispatch(setClaims(tokenResult.claims)));
          } else {
            dispatch(setClaims({ admin: false, loading: false }));
          }
        }))
    );
    return authUnsubscribe.current;
  }, [dispatch]);

  return (
    <ThemeWrapper>
      <Suspense fallback={<LoadingScreen />}>
        <Router>
          <Switch>
            {routeLngs.map((lang) => {
              const prefix = lang === 'en' ? '' : `/${lang}`;
              return (
                <Route
                  key={lang}
                  path={prefix}
                  render={({ match: { url } }) => (
                    <Switch>
                      <Route exact path={`${prefix}/`} component={withLanguageRedirect(LandingScreen)} />
                      <Route exact path={`${prefix}/contact`} component={withLanguageRedirect(ContactScreen)} />
                      <Route exact path={`${prefix}/departments`} component={withLanguageRedirect(DepartmentsScreen)} />
                      <Route
                        exact
                        path={`${prefix}/health-plans`}
                        component={withLanguageRedirect(HealthPlansScreen)}
                      />
                      <Route
                        exact
                        path={`${prefix}/departments/:name`}
                        component={withLanguageRedirect(DepartmentScreen)}
                      />
                      <Route
                        exact
                        path={`${prefix}/health-plans/:name`}
                        component={withLanguageRedirect(HealthPlanScreen)}
                      />
                      <Route exact path={`${prefix}/lab-tests`} component={withLanguageRedirect(LabTestsScreen)} />
                      <Route exact path={`${prefix}/login`} component={withLanguageRedirect(LoginScreen)} />
                      <Route exact path={`${prefix}/signup`} component={withLanguageRedirect(SignupScreen)} />
                      <Route exact path={`${prefix}/actions`} component={withLanguageRedirect(ActionsScreen)} />
                      <Route
                        exact
                        path={`${prefix}/verify-mobile`}
                        component={withLanguageRedirect(VerifyMobileScreen)}
                      />
                      <Route exact path={`${prefix}/dashboard`} component={withLanguageRedirect(DashboardScreen)} />
                      <Route component={withLanguageRedirect(NotFoundScreen)} />
                    </Switch>
                  )}
                />
              );
            })}

            <Route component={withLanguageRedirect(NotFoundScreen)} />
          </Switch>
        </Router>
      </Suspense>
    </ThemeWrapper>
  );
};

export default App;
