import { Redirect } from 'react-router-dom';
import { getEnglishUrl } from '../../../utils/translation_utils';
import { useTranslation } from 'react-i18next';

const withLanguageRedirect = (ChildComponent) => (props) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const supportedLngs = i18n.options.supportedLngs.filter((lng) => lng.length === 2);

  let urlLang = window.location.pathname.split('/')[1];
  urlLang = urlLang.length !== 2 ? null : urlLang.toLowerCase();

  if (urlLang) {
    if (urlLang !== 'en' && supportedLngs.includes(urlLang)) {
      if (i18n.language !== urlLang) i18n.changeLanguage(urlLang);
      return <ChildComponent {...props} />;
    }
    // Remove /en or non whitelisted languages from url
    if (i18n.language !== 'en') i18n.changeLanguage('en');
    return <Redirect to={getEnglishUrl()} />;
  } else if (i18n.language !== 'en') {
    i18n.changeLanguage('en');
    // return <></>;
  }

  return <ChildComponent {...props} />;
};

export default withLanguageRedirect;
